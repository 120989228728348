import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pw-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
})
export class RowComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
