import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import * as Sentry from '@sentry/angular';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { IEnvironment } from '@shared/core/interfaces/environment.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export interface IAuthTokens {
  access: string;
  refresh?: string;
  accepted_terms?: boolean;
}

export interface IDecodedAccessToken {
  exp: number;
  jti: string;
  name: string;
  first_name: string;
  last_name: string;
  platform_admin: boolean;
  platform_driver?: boolean;
  token_type: string;
  user_id: number;
  client_type: 'CARRIER' | 'SENDER' | 'UNDEFINED';
  client_name?: string;
  client_id?: number;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  public userName$: Observable<string>;
  public clientName$: Observable<string>;
  public decodedAccessToken$: Observable<IDecodedAccessToken>;
  private _afterLoginPath = 'after-login-link';
  private _accessToken = 'accessToken';
  private _refreshToken = 'refreshToken';
  private _acceptedTerms = 'acceptedTerms';
  private _autoRefreshTokensTimeoutId: number;
  private _decodedAccessToken$: BehaviorSubject<IDecodedAccessToken>;

  public isShowingAlert$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _http: HttpClient,
    private _router: Router,
    @Optional() @Inject(PW_ENVIRONMENT) public environment: IEnvironment,
    private _urls: UrlConstantsService,
    @Optional() private _angularFireAnalytics: AngularFireAnalytics
  ) {
    this._autoRefreshTokens();
    this._decodedAccessToken$ = new BehaviorSubject<IDecodedAccessToken>(this._getDecodedAccessToken());
    this.decodedAccessToken$ = this._decodedAccessToken$.asObservable();
    this.userName$ = this.decodedAccessToken$.pipe(map((data) => data?.name ?? ' '));
    this.clientName$ = this.decodedAccessToken$.pipe(map((data) => data?.client_name ?? ' '));
    if (this.isAuthenticated()) {
      Sentry.setUser({ email: this._decodedAccessToken$.value?.name });
    }
  }

  public hasAdminPreference(): boolean {
    return !!this._getDecodedAccessToken()?.platform_admin;
  }

  public hasSenderPreference(): boolean {
    return this._getDecodedAccessToken()?.client_type === 'SENDER';
  }

  public hasCarrierPreference(): boolean {
    return this._getDecodedAccessToken()?.client_type === 'CARRIER';
  }

  public hasDriverUserPreference(): boolean {
    const platformDriver = !!this._getDecodedAccessToken()?.platform_driver;
    return platformDriver;
  }

  public getUserId(): number {
    return this._getDecodedAccessToken()?.user_id;
  }

  public getClientId(): number {
    return this._getDecodedAccessToken()?.client_id;
  }

  public getClientName(): string {
    return this._getDecodedAccessToken()?.client_name;
  }

  public login(username, password): Observable<boolean> {
    this._angularFireAnalytics?.logEvent(`${this.environment.panelType.toLocaleLowerCase()}_try_login`);
    return this._http
      .post<IAuthTokens>(this._urls.LOGIN, { username, password })
      .pipe(
        tap(
          (tokens) => {
            Sentry.setUser({ email: username });
            this.saveAuthToken(tokens);
            this._angularFireAnalytics?.setUserId(username);
            this._angularFireAnalytics?.setUserProperties({
              client_id: this.getClientId(),
              client_name: this.getClientName(),
              platform_admin: this.hasAdminPreference(),
              carrier: this.hasCarrierPreference(),
              shipper: this.hasSenderPreference(),
              driver: this.hasDriverUserPreference(),
            });
            this._angularFireAnalytics?.logEvent(`${this.environment.panelType.toLocaleLowerCase()}_login`, {
              panel_type: this.environment.panelType,
              client_type: this._decodedAccessToken$.value.client_type,
              client_name: this._decodedAccessToken$.value.client_name,
            });
            this.isShowingAlert$.next(false);
          },
          (error) => {
            console.warn(error);
            this.isShowingAlert$.next(true);
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public loginWithToken(token: string): Observable<boolean> {
    this._angularFireAnalytics?.logEvent(`${this.environment.panelType.toLocaleLowerCase()}_try_token_login`);
    return this._http
      .post<IAuthTokens>(this._urls.LOGIN_WITH_TOKEN, { token })
      .pipe(
        tap(
          (tokens) => {
            this.saveAuthToken(tokens);
            Sentry.setUser({ email: this.getUserName() });
            this._angularFireAnalytics?.setUserId(this.getUserName());
            this._angularFireAnalytics?.setUserProperties({
              client_id: this.getClientId(),
              client_name: this.getClientName(),
              platform_admin: this.hasAdminPreference(),
              carrier: this.hasCarrierPreference(),
              shipper: this.hasSenderPreference(),
              driver: this.hasDriverUserPreference(),
            });
            this._angularFireAnalytics?.logEvent(`${this.environment.panelType.toLocaleLowerCase()}_login`, {
              panel_type: this.environment.panelType,
              client_type: this._decodedAccessToken$.value.client_type,
              client_name: this._decodedAccessToken$.value.client_name,
            });
            this.isShowingAlert$.next(false);
          },
          (error) => {
            console.warn(error);
            this.isShowingAlert$.next(true);
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }


  public refreshToken(): Observable<IAuthTokens> {
    return this._http
      .post<IAuthTokens>(this._urls.REFRESH_TOKENS, { refresh: this.getRefreshToken() })
      .pipe(tap((tokens) => this.saveAuthToken(tokens)));
  }

  public acceptTerms(): Observable<{ accepted_terms: boolean }> {
    return this._http
      .post<{ accepted_terms: boolean }>(this._urls.ACCEPT_TERMS, { accepted_terms: true })
      .pipe(
        tap((resp) => {
          if (!resp.accepted_terms) return;

          localStorage.setItem(this._acceptedTerms, 'true');
          this._router.navigate(['/']);
        })
      );
  }

  public getExpiration(): number {
    return this._getDecodedAccessToken()?.exp || null;
  }

  public isTokenExpired() {
    return this.getExpiration() < Date.now() / 1000;
  }

  public isAuthenticated(): boolean {
    return !!this._getDecodedAccessToken()?.user_id;
  }

  public logout(keepAfterLoginPath: boolean = false): void {
    this.clearAuthToken();
    Sentry.configureScope((scope) => scope.setUser(null));
    this._angularFireAnalytics?.logEvent('logout');
    if (keepAfterLoginPath) this.setAfterLoginPath(this._router.url);
    this._router.navigate(['/']);
  }

  public getUserName(): string {
    return this._getDecodedAccessToken()?.name ?? 'UNKNOWN';
  }

  public getFirstName(): string {
    return this._getDecodedAccessToken()?.first_name ?? 'UNKNOWN';
  }

  public getLastName(): string {
    return this._getDecodedAccessToken()?.last_name ?? 'UNKNOWN';
  }

  public getAccessToken(): string {
    return localStorage.getItem(this._accessToken);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(this._refreshToken);
  }

  public isTermsAccepted(): boolean {
    const accepted = localStorage.getItem(this._acceptedTerms) === 'true';
    console.log('terms accepted');
    return accepted;
  }

  public saveAuthToken(tokens: IAuthTokens): void {
    localStorage.setItem(this._accessToken, tokens.access);
    if (!!tokens.refresh) { localStorage.setItem(this._refreshToken, tokens.refresh); }
    if (!!tokens.accepted_terms) { localStorage.setItem(this._acceptedTerms, '' + tokens.accepted_terms); }
    this._decodedAccessToken$.next(this._getDecodedAccessToken(tokens.access));

    try {
      const value = {
        hasAdminPreference: this.hasAdminPreference(),
        hasSenderPreference: this.hasSenderPreference(),
        hasCarrierPreference: this.hasCarrierPreference(),
        now: new Date().toISOString()
      };
      document.cookie = `pickwings_auth=${JSON.stringify(value)};domain=.pickwings.ch`;
    } catch (error) {
      console.error(error);
    }

    this._autoRefreshTokens();
  }

  public clearAuthToken(): void {
    localStorage.removeItem(this._accessToken);
    localStorage.removeItem(this._refreshToken);
    localStorage.removeItem(this._acceptedTerms);
    this._decodedAccessToken$.next(this._getDecodedAccessToken(null));
    try {
      document.cookie = 'pickwings_auth=;Max-Age=0;domain=.pickwings.ch';
    } catch (error) {
      console.error(error);
    }
  }

  public getAfterLoginPath(): string {
    return sessionStorage.getItem(this._afterLoginPath) || '';
  }

  public setAfterLoginPath(path: string): void {
    sessionStorage.setItem(this._afterLoginPath, path);
  }

  public deleteAfterLoginPath(): void {
    sessionStorage.removeItem(this._afterLoginPath);
  }

  private _getDecodedAccessToken(accessToken: string = this.getAccessToken()): IDecodedAccessToken {
    try {
      return JSON.parse(atob(accessToken.split('.')[1]));
    } catch {
      return null;
    }
  }

  private _autoRefreshTokens(): void {
    if (!this.isAuthenticated()) return;

    const expirationPeriod = this.getExpiration() * 1000 - Date.now();
    clearTimeout(this._autoRefreshTokensTimeoutId);
    this._autoRefreshTokensTimeoutId = setTimeout(() => this.refreshToken().subscribe(), expirationPeriod);
  }
}
