export enum FileTypesEnum {
  EXCEL = 'application/xlsx',
  EXCEL_2 = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;',
  EXCEL_3 = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
  PAIN = 'application/xml',
}

export enum ShipmentFileTypesEnum {
  ORIGINAL_DELIVERY_NOTE = 0,
  DANGEROUS_GOODS_DOCUMENT = 1,
  SHIPMENT_IMAGE = 2,
  OTHER_DOCUMENT = 3,
}
