import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { IClientAccountMessage, IClientPreferences } from '@shared/core/interfaces/client-preferences.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ClientPreferencesService {
  public _clientPreferences$: BehaviorSubject<IClientPreferences> = new BehaviorSubject<IClientPreferences>(null);
  private _lastestAccountMessage$: BehaviorSubject<IClientAccountMessage> = new BehaviorSubject<IClientAccountMessage>(null);

  public get clientPreferences$(): Observable<IClientPreferences> {
    if (!this._isInitiated) {
      this._initClientPreference();
    }
    return this._clientPreferences$.asObservable();
  }

  public get lastestAccountMessage$(): Observable<IClientAccountMessage> {
    if (!this._isInitiated) {
      this._initClientPreference();
    }
    return this._lastestAccountMessage$.asObservable();
  }

  private _isInitiated = false;

  constructor(
    private _http: HttpClient,
    private authService: AuthService,
    private _url: UrlConstantsService
  ) {}

  private _initClientPreference(): void {
    this._isInitiated = true;
    if (!this.authService.isAuthenticated()){
      return;
    }
    this._http
      .get<IClientPreferences>(this._url.CLIENT_PREFERENCES)
      .subscribe((value) => {
        this._clientPreferences$.next(value);
        this.getLatestAccountMessage(value.client_type_2);
      });
  }

  private getLatestAccountMessage(type) {
    this._http
      .get<IClientAccountMessage>(this._url.CLIENT_ACCOUNT_MESSAGES(type)).subscribe((value) => {
        this._lastestAccountMessage$.next(value);
      });
  }

  public hideAccountMessage(key: string, data) {
    return this._http
    .post<any>(this._url.CLIENT_ACCOUNT_MESSAGES(key), data);
  }

}
