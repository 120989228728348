import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { IHealthStatus } from '@shared/core/interfaces/health-status';
import { UnhealthyInfoComponent } from '@utils/src/health-check/modals/unhealthy-info/unhealthy-info.component';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class HealthCheckService {
  public get healthInfo(): IHealthStatus {
    return this._healthStatus$.value;
  }

  public get isHealthy$(): Observable<boolean> {
    return this._healthStatus$.pipe(
      map((state) => state !== null),
      distinctUntilChanged()
    );
  }

  private _isInitiated = false;
  private _unhealthyInfoModalRef: MatDialogRef<UnhealthyInfoComponent> = null;
  private _healthStatus$: BehaviorSubject<IHealthStatus> = new BehaviorSubject<IHealthStatus>(undefined);

  constructor(private _http: HttpClient, private _urls: UrlConstantsService, private _dialog: MatDialog) {}

  public markAsUnhealthy(): void {
    this._healthStatus$.next(null);
  }

  public checkIfHealthy() {
    this._getApiHealthStatus();
  }

  public init(): void {
    if (this._isInitiated) return;

    this._isInitiated = true;
    this._getApiHealthStatus();
    this._manageUnhealthyModalSate();
  }

  private _getApiHealthStatus(doubleCheck: boolean = true): void {
    this._http
      .get<IHealthStatus & { build_date: string }>(this._urls.HEALTH_CHECK)
      .pipe(map((data) => ({ ...data, build_date: this._parse(data.build_date) })))
      .subscribe(
        (status) => {
          this._healthStatus$.next(status);
        },
        (error) => {
          console.error(error);
          if (doubleCheck) {
            setTimeout(() => {
              console.log('going to do the health status double check');
              this._getApiHealthStatus(false);
            }, 200);
          } else {
            this.markAsUnhealthy();
          }
        }
      );
  }

  private _parse(str: string): Date {
    const dateMatch = str.match(/^(\d{4})(\d{2})(\d{2})-(\d{2})(\d{2})(\d{2})$/);
    if (!dateMatch) return null;
    const [_, YYYY, MM, DD, HH, MIN, SEC] = dateMatch;
    return new Date(+YYYY, +MM - 1, +DD, +HH, +MIN, +SEC);
  }

  private _manageUnhealthyModalSate(): void {
    this.isHealthy$.subscribe((isHealthy) => (isHealthy ? this._hideUnhealthyInfo() : this._showUnhealthyInfo()));
  }

  private _showUnhealthyInfo(): void {
    this._unhealthyInfoModalRef = this._dialog.open(UnhealthyInfoComponent, { disableClose: true });
    interval(15000)
      .pipe(takeUntil(this.isHealthy$.pipe(filter((isHealthy) => isHealthy))))
      .subscribe(() => {
        console.log('rechecking health status');
        this._getApiHealthStatus();
      });
  }

  private _hideUnhealthyInfo(): void {
    this._unhealthyInfoModalRef?.close();
  }
}
