import { Component, Input } from '@angular/core';

@Component({
  selector: 'pw-centered-spinner',
  templateUrl: './centered-spinner.component.html',
  styleUrls: ['./centered-spinner.component.scss'],
})
export class CenteredSpinnerComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';
  @Input() isBlurred = true;
  @Input() isShadowed = false;
}
