import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@pw-utils/services/auth/auth.service';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private _urls: UrlConstantsService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.authService.isAuthenticated()) return next.handle(request);
    if (request.url.includes(this._urls.HEALTH_CHECK) || request.headers.has('Authorization'))
      return next.handle(request);

    const authClone = request.clone({ setHeaders: { Authorization: `Bearer ${this.authService.getAccessToken()}` } });
    return next.handle(authClone);
  }
}
