import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { TSort } from '@shared/core/types/sort.type';

@Component({
  selector: 'pw-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss'],
})
export class HeaderCellComponent {
  private _sort: TSort = null;

  @Input()
  @HostBinding('class')
  public get sort(): TSort {
    return this._sort;
  }

  public set sort(value: TSort) {
    if (value === this.sort) return;
    this._sort = value;
    this.sortChange.next(value);
  }

  @Input() @HostBinding('class.with-sort') public withSorting = false;
  @Output() public sortChange: EventEmitter<TSort> = new EventEmitter<TSort>();

  @HostListener('click')
  public onClick(): void {
    if (!this.withSorting) return;

    switch (this._sort) {
      case 'asc':
        this.sort = 'desc';
        break;
      case 'desc':
        this.sort = null;
        break;
      case null:
        this.sort = 'asc';
    }
  }
}
