import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
// import * as Sentry from "@sentry/angular-ivy";
import { Integrations } from '@sentry/tracing';
import { versions } from '@assets/versions';

if (environment.production) {
  Sentry.init({
    dsn: 'https://647fbbfabe9341e792b0869173a1f941@o516287.ingest.sentry.io/5737892',
    integrations: [
      // Sentry.replayIntegration(),
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'https://shipper.dev.pickwings.ch',
          'https://shipper.staging.pickwings.ch',
          'https://shipper.pickwings.ch',
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: versions.hash,
    dist: new Date(versions.date)
      .toISOString()
      .replace(/-/g, '')
      .replace(/T/g, '')
      .replace(/Z/g, '')
      .replace(/:/g, '')
      .replace('.', ''),
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1,
    environment: environment.envName,
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => {
    console.log(
      `🚚 🚚 🚚 Welcome to Pickwings!\nDoes this page need fixes or improvements? Send up some feedback at info@pickwings.ch!`
    );
    console.log(`Bootstrap success: ${versions.hash}, ${versions.date}`);
  })
  .catch((err) => console.error(err));
