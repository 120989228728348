import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientPreferencesService } from '@pw-utils/services/client-preferences/client-preferences.service';
import { OnePageListLoader } from '@shared/core/components/list-loader/list-loader.component';
import { InvoiceStatusEnum } from '@shared/core/enums/invoice-related.enum';
import { IInvoices } from '@shared/core/interfaces/invoices.interface';
import { IListResponse } from '@shared/core/interfaces/response.interface';
import { InvoicesService } from '@utils/src/utils/services/invoices/invoices.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent extends OnePageListLoader<IInvoices> implements OnInit, OnDestroy {
  public availableInvoiceStatus = InvoiceStatusEnum;
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    router: Router,
    route: ActivatedRoute,
    public preferences: ClientPreferencesService,
    private _invoicesService: InvoicesService,
    private translate: TranslateService,
    private title: Title
  ) {
    super(route, router);
    this.translate.stream(`invoices.page_title`)
      .pipe(takeUntil(this._unsubscribe$)).subscribe(val => {
        this.title.setTitle(val);
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public async downloadPDF(invoiceNumber: string) {
    try {
      this.isLoading = true;
      await this._invoicesService.getPdfById(invoiceNumber).toPromise();
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }

  }

  public async downloadExcel(invoiceNumber: string) {
    try {
      this.isLoading = true;
      await this._invoicesService.getExcelById(invoiceNumber).toPromise();
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  public async downloadReminderPDF(reminderId: number) {
    try {
      this.isLoading = true;
      await this._invoicesService.generateReminderPDF(reminderId).toPromise();
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  protected _loadListItems(): Observable<IListResponse<IInvoices>> {
    return this._invoicesService.getInvoices({ ...this._paginationDetails });
  }
}
