import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceTypeEnum } from '@shared/core/enums/invoice-related.enum';

@Pipe({
  name: 'invoiceType',
})
export class InvoiceTypePipe implements PipeTransform {
  transform(value: InvoiceTypeEnum): string {
    switch (value) {
      case InvoiceTypeEnum.COLLECTIVE_BILL:
        return 'invoices.body.collective_bill';
      case InvoiceTypeEnum.CREDIT_CARD_BILL:
        return 'invoices.body.credit_card_bill';
      default:
        return 'Unknown';
    }
  }
}
