import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class PwDateAdapter extends NativeDateAdapter {
  public parse(value: any): Date | null {
    const pwDateInputRegEx = /(\d\d).(\d\d).(\d\d\d\d)/;
    if (typeof value !== 'string' || !pwDateInputRegEx.test(value)) return super.parse(value);
    const [_, day, month, year] = value.match(pwDateInputRegEx);
    return new Date(+year, +month - 1, +day);
  }

  public format(date: Date, displayFormat: any): string {
    if (displayFormat === 'pw-date-input') {
      const year = date.getFullYear();
      const month = this._additionalZero(date.getMonth() + 1);
      const day = this._additionalZero(date.getDate());
      return `${day}.${month}.${year}`;
    }
    return super.format(date, displayFormat);
  }

  public getFirstDayOfWeek(): number {
    return 1;
  }

  public getDayOfWeekNames(): string[] {
    return super.getDayOfWeekNames('short');
  }

  private _additionalZero(num: number): string {
    return num > 9 ? `${num}` : `0${num}`;
  }
}
