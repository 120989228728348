import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { HealthCheckService } from '@pw-utils/services/health-check/health-check.service';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { ALLOWED_TO_REFRESH_HEADER_NAME } from '@shared/core/constants/http.constant';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class HealthStatusInterceptor implements HttpInterceptor {
  constructor(@Optional() private _healthCheck: HealthCheckService, private _urls: UrlConstantsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isAllowedToRefresh = this.isAllowedToRefresh(request);
    const pureRequest = this._removeControlHeaders(request);
    return next.handle(pureRequest).pipe(
      catchError((err) => {
        console.error(err);
        // handle error if 502 or 504 response returned from API
        if (err.status === 502 || err.status === 504) {
          // prevent circular dependency -> check if healthy does API request which would trigger itself again here if server down
          if (request.url !== this._urls.HEALTH_CHECK) {
            this._healthCheck?.checkIfHealthy();
          }

          // // refresh request if they were made when server didn't work
          // if (isAllowedToRefresh)
          //   return this._healthCheck.isHealthy$.pipe(
          //     filter((isHealth) => isHealth),
          //     take(1),
          //     switchMap(() => next.handle(pureRequest))
          //   );
        }

        // err.error will give the details payload from the server
        return throwError(err);
      })
    );
  }

  private isAllowedToRefresh(request: HttpRequest<unknown>): boolean {
    // if request has additional header 'Allowed-To-Refresh' return value of this header and erase it before send
    if (request.headers.has(ALLOWED_TO_REFRESH_HEADER_NAME))
      return request.headers.get(ALLOWED_TO_REFRESH_HEADER_NAME) === 'true';

    // in all other cases, by default repeat all GET requests
    return request.method === 'GET' && request.url !== this._urls.HEALTH_CHECK;
  }

  private _removeControlHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    if (!request.headers.has(ALLOWED_TO_REFRESH_HEADER_NAME)) return request;
    else return request.clone({ headers: request.headers.delete(ALLOWED_TO_REFRESH_HEADER_NAME) });
  }
}
