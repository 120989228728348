import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PwLocaleService } from '../../services/locale/locale.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private pwLocaleService: PwLocaleService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authClone = request.clone({ setHeaders: { 'Accept-Language': this.pwLocaleService.currentLanguage } });
    return next.handle(authClone);
  }
}
