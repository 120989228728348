<div class="page-block container-fluid">
  <h1 translate>invoices.title</h1>
  <div class="table-wrapper list-table">
    <pw-table *ngIf="!!items.length" style="min-width: 900px;">
      <pw-header>
        <pw-header-cell translate>invoices.header.number</pw-header-cell>
        <pw-header-cell translate>invoices.header.type</pw-header-cell>
        <pw-header-cell translate>invoices.header.valid_until</pw-header-cell>
        <pw-header-cell translate>invoices.header.total_price_with_vat</pw-header-cell>
        <pw-header-cell translate>invoices.header.status</pw-header-cell>
      </pw-header>
      <pw-row *ngFor="let invoice of items">
        <pw-cell>{{invoice.number}}</pw-cell>
        <pw-cell translate>{{ invoice.type | invoiceType | translate }}</pw-cell>
        <pw-cell [class.red]="invoice.status === availableInvoiceStatus.OPEN && invoice.is_overdue">
          {{invoice.valid_until | date: 'dd.MM.yyyy'}}
        </pw-cell>
        <pw-cell>{{invoice.total_price_with_vat}} CHF</pw-cell>
        <pw-cell translate>{{invoice.status | invoiceStatus | translate }}</pw-cell>
        <pw-cell class="additional-cell">
          <button (click)="downloadPDF(invoice?.number)" mat-flat-button color="primary">{{'invoices.header.pdf' | translate}}</button>
          <button (click)="downloadExcel(invoice?.number)"
            *ngIf="(preferences.clientPreferences$ | async)?.has_excel_invoice" mat-flat-button color="primary">
            {{'invoices.header.excel' | translate}}
          </button>
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{item: invoice}">
            <mat-icon>more_vert</mat-icon>
          </button> -->
        </pw-cell>
        <ng-container ngProjectAs="pw-cell" *ngIf="invoice.reminder">
          <pw-cell>
            <div>
              <div>{{invoice.number}}-{{invoice.reminder.reminder_type}}</div>
              <div class="red">{{invoice.sender.name}}</div>
            </div>
          </pw-cell>
          <pw-cell>1. {{'invoices.body.reminder' | translate}}</pw-cell>
          <pw-cell [class.red]="invoice.reminder.status === availableInvoiceStatus.OPEN && invoice.reminder.is_overdue">
            {{invoice.reminder.valid_until | date: 'dd.MM.yyyy'}}
          </pw-cell>
          <pw-cell>{{invoice.reminder.total_price_with_vat}} CHF</pw-cell>
          <pw-cell translate>{{invoice.reminder.status | invoiceStatus | translate }}</pw-cell>
          <pw-cell>
            <button (click)="downloadReminderPDF(invoice.reminder?.id)" *ngIf="invoice.reminder" mat-flat-button color="accent">
              1. {{'invoices.body.reminder_pdf' | translate}}
            </button>
          </pw-cell>
        </ng-container>
        <ng-container ngProjectAs="pw-cell" *ngIf="invoice.reminder?.next_reminder">
          <pw-cell>
            <div>
              <div>{{invoice.number}}-{{invoice.reminder.next_reminder.reminder_type}}</div>
              <div class="red">{{invoice.sender.name}}</div>
            </div>
          </pw-cell>
          <pw-cell>2. {{'invoices.body.reminder' | translate}}</pw-cell>
          <pw-cell
            [class.red]="invoice.reminder.next_reminder.status === availableInvoiceStatus.OPEN && invoice.reminder.next_reminder.is_overdue">
            {{invoice.reminder.next_reminder.valid_until | date: 'dd.MM.yyyy'}}
          </pw-cell>
          <pw-cell>{{invoice.reminder.next_reminder.total_price_with_vat}} CHF</pw-cell>
          <pw-cell translate>{{invoice.reminder.next_reminder.status | invoiceStatus | translate }}</pw-cell>
          <pw-cell>
            <button (click)="downloadReminderPDF(invoice.reminder?.next_reminder?.id)"
              *ngIf="invoice.reminder?.next_reminder" mat-flat-button color="warn">
              <!-- TODO: Sepehr translate -->
              {{'invoices.last_reminder' | translate}}
            </button>
          </pw-cell>
        </ng-container>
      </pw-row>
    </pw-table>
    <ng-container *ngIf="!isLoading && !items.length">
      <pw-no-data [translate]="'invoices.hint.no_data'"></pw-no-data>
    </ng-container>
    <pw-centered-spinner *ngIf="isLoading"></pw-centered-spinner>

    <!-- <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button (click)="downloadPDF(item?.number)" mat-menu-item>{{'invoices.header.pdf' | translate}}</button>
        <button (click)="downloadExcel(item?.number)"
          *ngIf="(preferences.clientPreferences$ | async)?.has_excel_invoice" mat-menu-item>
          {{'invoices.header.excel' | translate}}
        </button>
        <button (click)="downloadReminderPDF(item.reminder?.id)" *ngIf="item.reminder" mat-menu-item>
          1. {{'invoices.body.reminder_pdf' | translate}}
        </button>
        <button (click)="downloadReminderPDF(item.reminder?.next_reminder?.id)" *ngIf="item.reminder?.next_reminder"
          mat-menu-item>
          2. {{'invoices.body.reminder_pdf' | translate}}
        </button>
      </ng-template>
    </mat-menu> -->

  </div>

  <mat-paginator *ngIf="!!items?.length" (page)="onPaginationChange($event)" [length]="itemsCount"
    [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
    [showFirstLastButtons]="true"></mat-paginator>
</div>
