<div class="modal-heading" translate>
    common.health_modal_title
</div>
<div>
    <p translate>
        common.health_modal_message
    </p>
    <br> <br>
    <div class="d-grid gap-2 d-md-flex" >
        <a class="btn btn-primary text-center" href="https://status.pickwings.ch" style="margin-right: 10px;">Status</a>
        <a class="btn btn-primary text-center pull-right" routerLink="" (click)="forceReload()">Refresh</a>
     </div>

    <br> <br>
    <p>&mdash; {{'common.health_the_pickwings_team' | translate}} </p>
</div>