export enum InvoiceStatusEnum {
  OPEN = 1,
  PAID = 2,
  CANCELED = 3,
}

export enum LockStatusEnum {
  UNLOCKED = 0,
  LOCKED = 1,
}

export enum InvoiceTypeEnum {
  COLLECTIVE_BILL = 1,
  CREDIT_CARD_BILL = 2,
}

export enum InvoiceReminderTypeEnum {
  FIRST_REMINDER = 1,
  SECOND_REMINDER = 2,
}
