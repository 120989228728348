import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@pw-utils/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // check if user is authenticated and if the user is platform admin
    if (!this.authService.isAuthenticated()) return this.router.parseUrl(`${route.data?.loginPath}#${state.url}`);
    if (route.data?.needAdminAccess && !this.authService.hasAdminPreference())
      return this.router.parseUrl('/access-denied');
    return true;
  }
}
