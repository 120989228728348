import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatusEnum, InvoiceTypeEnum } from '@shared/core/enums/invoice-related.enum';

@Pipe({
  name: 'invoiceStatus',
})
export class InvoiceStatusPipe implements PipeTransform {
  transform(value: InvoiceStatusEnum): string {
    switch (value) {
      case InvoiceStatusEnum.OPEN:
        return 'invoices.body.open';
      case InvoiceStatusEnum.PAID:
        return 'invoices.body.paid';
      case InvoiceStatusEnum.CANCELED:
        return 'invoices.body.canceled';
      default:
        return 'Unknown';
    }
  }
}
