import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pw-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit {
  @Input('translate') translate: string;

  displayTranslatedString: string;

  constructor() {}

  ngOnInit(): void {
    this.displayTranslatedString = this.translate;
  }
}
