import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IListResponse } from '@shared/core/interfaces/response.interface';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
export abstract class ListLoader<T> implements OnDestroy, OnInit {
  public items: T[] = [];
  public isLoading = false;
  protected _isLastLoaded = false;
  protected _paginationDetails = { offset: 0, limit: 20 };
  protected _unsubscribeList$: Subject<void> = new Subject<void>();

  public ngOnInit(): void {
    this.loadNextPage();
  }

  public ngOnDestroy(): void {
    this._unsubscribeList$.next();
    this._unsubscribeList$.complete();
  }

  public loadNextPage(): void {
    if (this.isLoading || this._isLastLoaded) return;
    this.isLoading = true;

    // example of usual interrupting (unsubscribe on load next page on reloading data)
    this._loadListItems()
      .pipe(takeUntil(this._unsubscribeList$))
      .subscribe((data: IListResponse<T>) => this._addItemsData(data))
      .add(() => (this.isLoading = false));
  }

  public abstract reloadData(...params: any): void;

  protected abstract _loadListItems(): Observable<IListResponse<T>>;

  protected abstract _addItemsData(data: IListResponse<T>): void;
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
export abstract class InfiniteListLoader<T> extends ListLoader<T> implements OnDestroy, OnInit {
  public reloadData(...params: any): void {
    this.items = [];
    this._paginationDetails.offset = 0;
    this._unsubscribeList$.next();
    this.isLoading = false;
    this._isLastLoaded = false;
    this.loadNextPage();
  }

  protected _addItemsData(data: IListResponse<T>): void {
    if (!data) return;

    this._paginationDetails.offset += data.results?.length ?? 0;
    this._isLastLoaded = this._paginationDetails.offset >= data.count;
    this.items.push(...(data.results ?? []));
  }
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
export abstract class OnePageListLoader<T> extends ListLoader<T> implements OnDestroy, OnInit {
  public get pageSize(): number {
    return this._paginationDetails.limit;
  }

  public get pageIndex(): number {
    return this._paginationDetails.page - 1;
  }

  public pageSizeOptions = [10, 25, 50, 100, 200, 500];
  public itemsCount = 0;
  protected _paginationDetails: any = { offset: 0, limit: 20, page: 1 };
  protected _filters: Params = {};
  protected _unsubscribeRoute$: Subject<void> = new Subject<void>();

  constructor(protected _route: ActivatedRoute, protected _router: Router) {
    super();
  }

  public ngOnInit(): void {
    this._listenRoute();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribeRoute$.next();
    this._unsubscribeRoute$.complete();
  }

  public onPaginationChange(pageEvent: PageEvent): void {
    const queryParams: Params = { page: pageEvent.pageIndex + 1, size: pageEvent.pageSize, ...this._filters };
    this._router.navigate([], { queryParams, replaceUrl: true });
  }

  public reloadData(...params: any): void {
    this.isLoading = false;
    this._unsubscribeList$.next();
    this.loadNextPage();
  }

  protected _parseRestRouteParams(params?: Params): void {}

  protected _addItemsData(data: IListResponse<T>): void {
    this.items = data?.results ?? [];
    this.itemsCount = data?.count || 0;
  }

  protected _listenRoute(): void {
    this._route.queryParams.pipe(takeUntil(this._unsubscribeRoute$)).subscribe((params) => this._onRouteChange(params));
  }

  protected _onRouteChange({ page = 1, size = 20, ...params }: Params): void {
    this._paginationDetails.limit = +size;
    this._paginationDetails.page = +page;
    this._paginationDetails.offset = (+page - 1) * +size;
    this.isLoading = false;
    this._parseRestRouteParams(params);
    this.reloadData();
  }
}
