import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, IAuthTokens } from '@pw-utils/services/auth/auth.service';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private _isTokenUpdating = false;
  private _refreshResponse: Subject<IAuthTokens>;

  constructor(private _authService: AuthService, private _urls: UrlConstantsService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (request.url !== this._urls.REFRESH_TOKENS && err.status === 401 && !!this._authService.getRefreshToken())
          return this._updateToken().pipe(
            switchMap((tokens) => {
              const authClone = request.clone({ setHeaders: { Authorization: `Bearer ${tokens.access}` } });
              return next.handle(authClone);
            }),
            catchError((_) => throwError(err))
          );

        return throwError(err);
      })
    );
  }

  private _updateToken(): Observable<IAuthTokens> {
    if (!this._isTokenUpdating) {
      this._refreshResponse?.complete();
      this._refreshResponse = new Subject<IAuthTokens>();
      this._isTokenUpdating = true;
      this._authService
        .refreshToken()
        .subscribe(this._refreshResponse)
        .add(() => (this._isTokenUpdating = false));
    }
    return this._refreshResponse.asObservable();
  }
}
