import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { HealthCheckService } from '@pw-utils/services/health-check/health-check.service';
import { PwTranslateLoader } from '../utils/services/translate-loader/translate-loader.service';
import { UnhealthyInfoComponent } from './modals/unhealthy-info/unhealthy-info.component';

@NgModule({
  declarations: [UnhealthyInfoComponent],
  imports: [CommonModule, MatDialogModule, TranslateModule],
  providers: [HealthCheckService],
})
export class HealthCheckModule {
  constructor(private _translateLoader: PwTranslateLoader, private _healthCheck: HealthCheckService) {
    this._healthCheck.init();
    this._translateLoader.addModule({ name: 'common', relateLocation: 'shared/common' });
  }
}
