import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ALLOWED_TO_REFRESH_HEADER_NAME } from '@shared/core/constants/http.constant';
import { FileTypesEnum } from '@shared/core/enums/file-types.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class DownloadService {
  public httpOptions = {
    // important to access the headers
    observe: 'response' as 'body',
    responseType: 'arraybuffer' as 'json',
    headers: new HttpHeaders().append(ALLOWED_TO_REFRESH_HEADER_NAME, 'true'),
  };

  constructor(private _toastr: ToastrService, private _translate: TranslateService) { }

  public downloadFileFromResponse(response: HttpResponse<any>, type: FileTypesEnum): void {
    console.log(response.headers.keys())
    if (type == FileTypesEnum.EXCEL) {
      // Backend can send different types, EXCEL, EXCEL_2, EXCEL_3
    } else {
      if (type !== response?.headers?.get('content-type')) {
        this._translate
          .get('common.load_file_error_title')
          .subscribe((title) => this._toastr.error(response?.body?.detail, title));
        return;
      }
    }

    const blob = new Blob([response.body], { type });
    const name = this._parseFilename(response.headers.get('content-disposition'));
    this._downloadFile(blob, name);
  }

  private _parseFilename(contentDisposition): string {
    if (!contentDisposition) return null;
    const fileNameRaw = contentDisposition.split('filename=')[1];
    return fileNameRaw.replace(/"/g, '');
  }

  private _downloadFile(blob: Blob, filename: string) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
