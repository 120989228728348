export enum PanelTypeEnum {
  ADMIN = 'admin',
  ADMIN_DRAFT = 'admin_draft',
  SHIPPER = 'shipper',
  SHIPPER_DRAFT = 'shipper_draft',
  CARRIER = 'carrier',
  CARRIER_DRAFT = 'carrier_draft',
  CONSIGNEE = 'consignee',
  ABOUT = 'about',
}
