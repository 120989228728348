import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { versions } from '@shared/assets/versions';
import { APP_NAME } from '@angular/fire/compat/analytics';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_NAME) public appName,
  ) {
    console.log(this.appName);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appName = this.appName || '';
    const requestWithVersion = request.clone({
      setHeaders: {
        'x-app-name': appName,
        'x-app-build-date': versions.date,
        'x-app-hash': versions.hash
      }
    });
    return next.handle(requestWithVersion);
  }
}
