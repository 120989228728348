import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '@pickwings-shipper/src/environments/environment';
import { PW_TRANSLATION_DEFAULT_CONFIG, PW_TRANSLATION_ROOT_PATH, PwTranslateLoader } from '@pw-translate-loader';
import { AuthInterceptor } from '@pw-utils/interceptors/auth/auth.interceptor';
import { ErrorInterceptor } from '@pw-utils/interceptors/error/error.interceptor';
import { HealthStatusInterceptor } from '@pw-utils/interceptors/health-status/health-status.interceptor';
import { RefreshTokenInterceptor } from '@pw-utils/interceptors/refresh-token/refresh-token.interceptor';
import { PW_AVAILABLE_LANGUAGES, PwLocaleService } from '@pw-utils/services/locale/locale.service';
import * as Sentry from '@sentry/angular';
import { versions } from '@shared/assets/versions';
import { PwDateAdapter } from '@shared/core/classes/date-adapter.class';
import { PW_DATE_FORMATS } from '@shared/core/constants/datepicker-format.constant';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { HealthCheckModule } from '@utils/src/health-check/health-check.module';
import { LanguageInterceptor } from '@utils/src/utils/interceptors/language/language.interceptor';
import { AppVersionInterceptor } from '@utils/src/utils/interceptors/app-version/app-version.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ModalModule,
    FormsModule,
    HttpClientModule,
    MatNativeDateModule,
    HealthCheckModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useExisting: PwTranslateLoader } }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    DatePipe,
    PwTranslateLoader,
    ScreenTrackingService,
    { provide: DateAdapter, useClass: PwDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: PW_DATE_FORMATS },
    {
      provide: PW_TRANSLATION_DEFAULT_CONFIG,
      useValue: [
        { name: 'app', relateLocation: 'pickwings-shipper/app' },
        { name: 'common', relateLocation: 'shared/common' },
        { name: 'placeholder', relateLocation: 'shared/placeholder' },
        { name: 'toastr', relateLocation: 'shared/toastr' },
      ],
    },
    { provide: PW_TRANSLATION_ROOT_PATH, useValue: '/assets/locale' },
    { provide: PW_AVAILABLE_LANGUAGES, useValue: environment.languages },
    { provide: PW_ENVIRONMENT, useValue: environment },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_NAME, useValue: 'shipper' },
    { provide: APP_VERSION, useValue: versions.hash },
    { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HealthStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
      deps: [
        APP_NAME
      ]
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private _translate: TranslateService,
    private _locale: PwLocaleService
  ) {
    registerLocaleData(localeIT, 'it');
    registerLocaleData(localeFR, 'fr');
    registerLocaleData(localeDE, 'de');
    registerLocaleData(localeEN, 'en');

    this._locale.init();
    this._locale.listenLocale().subscribe(val => {
      // console.log(`AppModule : listenLocale`);
      setTimeout(() => {
        this._translate.use('de');
        this._translate.use(val);
      }, 100);
    });

  }
}
