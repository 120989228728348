import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UrlConstantsService } from '@pw-utils/services/url-constants/url-constants.service';
import { FileTypesEnum } from '@shared/core/enums/file-types.enum';
import { IInvoices } from '@shared/core/interfaces/invoices.interface';
import { IListResponse } from '@shared/core/interfaces/response.interface';
import { Observable, tap } from 'rxjs';

import { DownloadService } from '../download.service';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  constructor(
    private _http: HttpClient,
    private _url: UrlConstantsService,
    private _downloadService: DownloadService,
  ) { }

  public getInvoices(params: Params): Observable<IListResponse<IInvoices>> {
    return this._http.get<IListResponse<IInvoices>>(this._url.INVOICES, { params });
  }

  public getPdfById(invoiceId: string) {
    return this._http
      .get<HttpResponse<any>>(this._url.INVOICES_DOWNLOAD_PDF_BY_ID(invoiceId), this._downloadService.httpOptions)
      .pipe(
        tap((resp) => {
          this._downloadService.downloadFileFromResponse(resp, FileTypesEnum.PDF)
        })
      );
  }

  public getExcelById(invoiceId: string) {
    return this._http
      .get<HttpResponse<any>>(this._url.INVOICES_DOWNLOAD_EXCEL_BY_ID(invoiceId), this._downloadService.httpOptions)
      .pipe(
        tap((resp) => {
          this._downloadService.downloadFileFromResponse(resp, FileTypesEnum.EXCEL)
        })
      );
  }

  public generateReminderPDF(reminderId: number) {
    const body = { reminder_id: reminderId };
    return this._http
      .post<any>(this._url.DOCUMENTS_REMINDER, body, this._downloadService.httpOptions)
      .pipe(
        tap((resp) => {
          this._downloadService.downloadFileFromResponse(resp, FileTypesEnum.PDF)
        })
      );
  }
}
