import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SwUpdate } from '@angular/service-worker';
import { PW_ENVIRONMENT } from '@shared/core/constants/injectors.constant';
import { PanelTypeEnum } from '@shared/core/enums/panel-type.enum';
import { IEnvironment } from '@shared/core/interfaces/environment.interface';
import { Subject } from 'rxjs';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
export class AppWithSwComponent implements OnInit, OnDestroy {
  private _unsubscribe$: Subject<void> = new Subject<void>();
  deferredPrompt = null;

  constructor(
    private _updates: SwUpdate,
    @Optional() @Inject(PW_ENVIRONMENT) public environment: IEnvironment,
    @Optional() private _angularFireAnalytics: AngularFireAnalytics
  ) {
    if (this.environment.envName === 'local_dev') {
      this._angularFireAnalytics?.setAnalyticsCollectionEnabled(false);
    }

    window.addEventListener('beforeinstallprompt', (e: any) => {
      try {
        if (environment.panelType === PanelTypeEnum.ABOUT || environment.panelType === PanelTypeEnum.CONSIGNEE) {
          // no not show message to install about page
          console.log('beforeinstallprompt Event fired');
          e.preventDefault();
          // Stash the event so it can be triggered later.
          this.deferredPrompt = e.originalEvent;
          return false;
        }

        console.log('beforeinstallprompt: ');
        console.log(e);
        e.userChoice.then((choiceResult) => {
          this._angularFireAnalytics?.logEvent(
            `${this.environment.panelType.toLocaleLowerCase()}_before_install_web_app_prompt`,
            {
              panelType: this.environment.panelType,
              outcome: choiceResult.outcome,
            }
          );
        });
      } catch (error) {
        console.error(error);
      }
    });

    window.addEventListener('appinstalled', (e: any) => {
      try {
        console.log('appinstalled' + e);
        this._angularFireAnalytics?.logEvent(
          `${this.environment.panelType.toLocaleLowerCase()}_installed_web_app_to_home_screen`,
          {
            panelType: this.environment.panelType,
          }
        );
      } catch (error) {
        console.error(error);
      }
    });
  }

  public ngOnInit(): void {
    if (this.checkIfInstalledWebApp()) {
      this._angularFireAnalytics?.logEvent(
        `${this.environment.panelType.toLocaleLowerCase()}_using_installed_web_app`,
        {
          panelType: this.environment.panelType,
        }
      );
    }

    if (this._updates.isEnabled) {
      this._updates.checkForUpdate();
    }
    this._updates.activated.subscribe((event) => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
      console.log('activated', event);
    });
    this._updates.unrecoverable.subscribe((event) => {
      console.error('unrecoverable', event);
      console.error(event.reason);
    });
    this._updates.available.subscribe((event) => {
      console.log('available', event);
      console.log('old version was', event.current);
      console.log('new version is', event.available);
      this._angularFireAnalytics?.logEvent(`${this.environment.panelType}_update_available`, {
        panelType: this.environment.panelType,
      });
      if (this.environment.showUpdateAvailable) {
        if (confirm('There is a new version of this app, you want to refresh now?') === true) {
          this._updates.activateUpdate().then(() => {
            this._angularFireAnalytics?.logEvent(
              `${this.environment.panelType.toLocaleLowerCase()}_performing_update`,
              {
                panelType: this.environment.panelType,
              }
            );
            document.location.reload();
          });
        } else {
          this._angularFireAnalytics?.logEvent(
            `${this.environment.panelType.toLocaleLowerCase()}_not_performing_update`,
            {
              panelType: this.environment.panelType,
            }
          );
          console.log('not performing update');
        }
      } else if (this.environment.automaticallyUpdate) {
        this._updates.activateUpdate().then(() => {
          this._angularFireAnalytics?.logEvent(
            `${this.environment.panelType.toLocaleLowerCase()}_performing_update`,
            {
              panelType: this.environment.panelType,
            }
          );
          document.location.reload();
        });
      }
    });
  }

  private checkIfInstalledWebApp() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }
    return false;
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
