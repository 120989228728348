import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class SupportGuard implements CanActivate, CanLoad {
  constructor(private _router: Router) {}

  public static isIEBrowser(): boolean {
    return navigator.userAgent.indexOf('MSIE') !== -1 || document.hasOwnProperty('documentMode');
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return !SupportGuard.isIEBrowser() || this._router.parseUrl(route.data?.unSupportPath);
  }

  public canLoad(route: Route): boolean | UrlTree {
    return !SupportGuard.isIEBrowser() || this._router.parseUrl(route.data?.unSupportPath);
  }
}
