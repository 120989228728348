import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mergeMap, reduce, retry, switchMap, take, tap } from 'rxjs/operators';

export const PW_TRANSLATION_DEFAULT_CONFIG = new InjectionToken<IModuleLocationConfig[]>(
  'PW_TRANSLATION_DEFAULT_CONFIG'
);
export const PW_TRANSLATION_ROOT_PATH = new InjectionToken<string>('PW_TRANSLATION_ROOT_PATH');

export interface IModuleLocationConfig {
  name: string;
  relateLocation: string;
}

@Injectable()
export class PwTranslateLoader implements TranslateLoader {
  private readonly _moduleNames = [];
  private _moduleNames$: Subject<string[]> = new Subject<string[]>();
  private _moduleConfigs: Map<string, IModuleLocationConfig> = new Map<string, IModuleLocationConfig>();
  private _moduleCache: Map<string, Map<string, object>> = new Map<string, Map<string, object>>();
  private _moduleRequests: Map<string, Map<string, Observable<object>>> = new Map();

  constructor(
    private _http: HttpClient,
    @Optional() @Inject(PW_TRANSLATION_ROOT_PATH) private readonly _modulesLocationPath: string,
    @Optional() @Inject(PW_TRANSLATION_DEFAULT_CONFIG) moduleConfigs: IModuleLocationConfig[]
  ) {
    this.addModule(...(moduleConfigs || []));
  }

  public getTranslation(lang: string): Observable<object> {
    return from(this._moduleNames).pipe(
      mergeMap((moduleName) => this._loadModule(moduleName, lang)),
      reduce((vocabulary, module) => ({ ...vocabulary, ...module }), {}),
      switchMap((vocabulary) => {
        const vocabularyModuleNamesSet = new Set(Object.keys(vocabulary));
        const hasUnloadedModules = this._moduleNames.some((moduleName) => !vocabularyModuleNamesSet.has(moduleName));
        return hasUnloadedModules ? throwError(vocabulary) : of(vocabulary);
      }),
      retry(3),
      catchError((vocabulary) => of(vocabulary))
    );
  }

  public addModule(...moduleConfigs: IModuleLocationConfig[]): void {
    let hasChanges = false;

    moduleConfigs.forEach((config) => {
      if (this._moduleNames.includes(config.name)) return;

      this._moduleNames.push(config.name);
      this._moduleConfigs.set(config.name, config);
      hasChanges = true;
    });

    if (hasChanges) this._moduleNames$.next([...this._moduleNames]);
  }

  public listenModuleChanges(): Observable<string[]> {
    return this._moduleNames$.asObservable();
  }

  public clearCache(moduleName?: string, lang?: string): void {
    if (!moduleName) this._moduleCache.clear();
    else if (!lang) this._moduleCache.delete(moduleName);
    else this._moduleCache.get(moduleName)?.delete(lang);
  }

  public hasTranslationInCache(moduleName: string, lang: string): boolean {
    return this._moduleCache.has(moduleName) && this._moduleCache.get(moduleName).has(lang);
  }

  public hasTranslationRequest(moduleName: string, lang: string): boolean {
    return this._moduleRequests.has(moduleName) && this._moduleRequests.get(moduleName).has(lang);
  }

  private _getTranslationFromCache(moduleName: string, lang: string): object {
    return this._moduleCache.get(moduleName)?.get(lang);
  }

  private _getTranslationRequest(moduleName: string, lang: string): Observable<object> {
    return this._moduleRequests.get(moduleName)?.get(lang);
  }

  private _loadModule(moduleName: string, lang: string): Observable<object> {
    if (this.hasTranslationInCache(moduleName, lang)) return of(this._getTranslationFromCache(moduleName, lang));
    if (this.hasTranslationRequest(moduleName, lang)) return this._getTranslationRequest(moduleName, lang);
    return this._loadModuleTranslation(moduleName, lang);
  }

  private _addModuleTranslationToCache(moduleName: string, lang: string, translation: object): void {
    if (this._moduleCache.has(moduleName)) this._moduleCache.get(moduleName).set(lang, translation);
    else this._moduleCache.set(moduleName, new Map([[lang, translation]]));
    this._moduleRequests.get(moduleName)?.delete(lang);
    if (this._moduleRequests.get(moduleName)?.size === 0) this._moduleRequests.delete(moduleName);
  }

  private _storeModuleRequest(moduleName: string, lang: string, request: Observable<object>): void {
    if (this._moduleRequests.has(moduleName)) this._moduleRequests.get(moduleName).set(lang, request);
    else this._moduleRequests.set(moduleName, new Map([[lang, request]]));
  }

  private _loadModuleTranslation(moduleName: string, lang: string): Observable<object> {
    const request = new Subject<object>();
    this._storeModuleRequest(moduleName, lang, request.asObservable());

    const url = `${this._modulesLocationPath}/${this._moduleConfigs.get(moduleName)?.relateLocation}/${lang}.json`;
    this._http
      .get(url)
      .pipe(
        map((translation) => ({ [moduleName]: translation })),
        tap((translation) => this._addModuleTranslationToCache(moduleName, lang, translation)),
        retry(3),
        catchError(() => of({}).pipe(take(0)))
      )
      .subscribe(request);

    return request;
  }
}
